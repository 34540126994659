<template>
    <div id="app" :hidden="is_hidden">
        <!-- 模板 -->
        <div class="header" :hidden="is_alipay||is_wxpay">
            <van-nav-bar :title="title"></van-nav-bar>
        </div>
        <div v-if="!is_pause&&!is_error" class="content">
            <div style="display: flex;align-items: center;">
                <div style="flex:1;">
                    <div>付款给商家</div>
                    <div style="font-size:1.4rem;font-weight: bold;">{{store_info.shopName}}</div>
                </div>
                <div>
                    <van-image
                            radius="8px"
                            width="60px"
                            height="60px"
                            fit="cover"
                            :src="store_info.headImage"
                    />
                </div>
            </div>
            <van-divider></van-divider>
            <div v-if="is_fixed">
                <div>
                    <div>货款</div>
                </div>
                <div class="money">￥{{amount}}</div>
            </div>
            <div v-else>
                <div>付款金额(必填)</div>
                <div class="money_bg">
                    <div>￥</div>
                    <div>
                        <van-field
                                :formatter="formatter" v-model="amount" placeholder="输入金额" type="number"
                                style="background: #f5f5f5;font-size: 2rem;"></van-field>
                    </div>
                </div>
            </div>
            <div>
                <div>商品类型(必选)</div>
                <div>
                    <van-checkbox-group v-model="result" @change="onChange">
                        <van-row>
                            <van-col class="order-type" span="8" v-for="(item,index) in goods_type" :key="index">
                                <!--                                <van-checkbox :name="item.name"  shape="square" checked-color="#ef2505">{{item.label}}</van-checkbox>-->
                                <div @click="chooseGoodsType(index)"
                                     :class="item.checked == true?['goods-type','goods-type-choose']:['goods-type']">
                                    <span>{{item.productTypeName}}</span>
                                    <img :src="bill_icon_select" width="20px"
                                         style="position: absolute;right: -1px;bottom: -1px;"
                                         v-if="item.checked == true">
                                </div>
                            </van-col>
                        </van-row>
                    </van-checkbox-group>
                </div>
            </div>
            <van-divider></van-divider>
            <div class="remarks_panel">
                <div class="remarks" v-if="remarks == ''" @click="addRemarks">
                    添加备注
                </div>
                <div v-else>
                    {{remarks}}<span class="remarks-modify" @click="addRemarks">修改</span>
                </div>
                <van-dialog v-model="remarks_show" title="添加备注" @confirm="confirmRemarks" show-cancel-button>
                    <div style="padding: 30px;">
                        <van-field v-model="remarks_tmp" :autofocus="autofocus" label="" border placeholder="请输入备注内容"
                                   style="border-bottom: #ff4444 1px solid;padding: 0px;"></van-field>
                    </div>
                </van-dialog>
            </div>
            <div class="btn" v-show="isOriginHei">
                <van-button type="primary" color="#ef2505" style="width:100%;" block @click="submitOrder">提交订单
                </van-button>
            </div>
            <van-popup v-model="popup_show"
                       position="bottom" round><!-- :close-on-click-overlay="false"-->
                <div>
                    <div>
                        <div style="padding: 14px 0px;text-align: center;background: #f0f0f0;position: relative;">
                            <div style="position: absolute;left: 0px;padding:0px 20px;" @click="closePayType">
                                <van-icon name="cross"/>
                            </div>
                            <div>选择付款方式</div>
                        </div>
                        <div>
                            <van-radio-group v-model="radio">
                                <van-cell-group>
                                    <van-cell v-if="is_alipay" class="pay-type-item" title="单选框 1" clickable
                                              @click="radio = '1'">
                                        <template #title>
                                            <div style="display: flex;align-items: center;">
                                                <van-icon :name="pay_icon_alipay" size="30"></van-icon>
                                                <div style="margin-left: 16px;">
                                                    <div class="pay-type-name">支付宝</div>
                                                </div>
                                            </div>
                                        </template>
                                        <template #right-icon>
                                            <van-radio name="1" checked-color="#ef2505"/>
                                        </template>
                                    </van-cell>
                                    <van-cell v-if="is_wxpay" class="pay-type-item" title="单选框 1" clickable
                                              @click="radio = '2'">
                                        <template #title>
                                            <div style="display: flex;align-items: center;">
                                                <van-icon :name="pay_icon_wechat" size="30"></van-icon>
                                                <div style="margin-left: 16px;">
                                                    <div class="pay-type-name">微信支付</div>
                                                </div>
                                            </div>
                                        </template>
                                        <template #right-icon>
                                            <van-radio name="2" checked-color="#ef2505"/>
                                        </template>
                                    </van-cell>
                                    <!--<van-cell class="pay-type-item" title="单选框 2" clickable @click="radio = '3'">
                                        <template #title>
                                            <div style="display: flex;align-items: center;">
                                                <van-icon :name="pay_icon_wallet" size="30"></van-icon>
                                                <div style="margin-left: 16px;">
                                                    <div style="display: flex;align-items: center;">
                                                        <div class="pay-type-name">芝富通</div>
                                                        <van-tag type="danger"
                                                                 style="font-size: 10px;margin-left: 4px;">推荐
                                                        </van-tag>
                                                    </div>
                                                    <div class="pay-type-desc">随机立减，每单最高可减50元</div>
                                                </div>
                                            </div>
                                        </template>
                                        <template #right-icon>
                                                                  <van-radio name="3" checked-color="#ef2505" />
                                            <div style="color: #ef2505;display: flex;align-items: center;border: 1px solid #ef2505;border-radius: 90px;height: 24px;padding: 0px 4px;margin-top: 10px;">
                                                <div style="font-size: 12px;">立即开通</div>
                                                <van-icon name="arrow"></van-icon>
                                            </div>
                                        </template>
                                    </van-cell>-->
                                </van-cell-group>
                            </van-radio-group>
                        </div>
                    </div>
                    <div style="padding: 20px;">
                        <van-button type="primary" color="#ef2505" style="width:100%;" block @click="payOrder">提交订单
                        </van-button>
                    </div>
                </div>
            </van-popup>
        </div>
        <div v-else-if="is_pause" class="content-pause">
            <div style="display: flex;flex-direction: column;align-items: center;">
                <van-image :src="code_blank" width="200px"></van-image>
                <div style="margin:20px 0px;">该二维码已暂停使用，<br/>如有疑问请联系商家。</div>
                <div style="width: 260px;">
                    <!--                    <van-button round block v-if="is_alipay">重新扫码</van-button>-->
                </div>
            </div>
        </div>
        <div v-else class="content-pause">
            <div style="display: flex;flex-direction: column;align-items: center;">
                <van-image :src="code_blank" width="200px"></van-image>
                <div style="margin:20px 0px;">非法访问！</div>
                <div style="width: 260px;">
                    <!--                    <van-button round block v-if="is_alipay">重新扫码</van-button>-->
                </div>
            </div>
        </div>
        <div>{{res}}</div>
    </div>
</template>
<script>
    import {
        Dialog,
        Button,
        Row,
        Col,
        Icon,
        Cell,
        CellGroup,
        Field,
        Checkbox,
        CheckboxGroup,
        NavBar,
        Image,
        Divider,
        Tag,
        Radio,
        Popup,
        RadioGroup,
        Toast
    } from 'vant';
    // import wx from 'weixin-js-sdk';

    export default {
        name: "index",
        components: {
            [Dialog.Component.name]: Dialog.Component,
            [Button.name]: Button,
            [Row.name]: Row,
            [Col.name]: Col,
            [Icon.name]: Icon,
            [Cell.name]: Cell,
            [CellGroup.name]: CellGroup,
            [Field.name]: Field,
            [Checkbox.name]: Checkbox,
            [CheckboxGroup.name]: CheckboxGroup,
            [NavBar.name]: NavBar,
            [Image.name]: Image,
            [Divider.name]: Divider,
            [Tag.name]: Tag,
            [Popup.name]: Popup,
            [Radio.name]: Radio,
            [RadioGroup.name]: RadioGroup,
            [Toast.name]: Toast
        },
        data() {
            return {
                is_hidden:false,
                autofocus: false,
                res: '',
                radio: '',
                result: [],
                goods_type: [],
                remarks: '',
                remarks_tmp: '',
                remarks_show: false,
                popup_show: false,
                is_fixed: false,
                amount: '',
                pay_icon_wallet: require("../../assets/pay_icon_wallet.png"),
                pay_icon_alipay: require("../../assets/pay_icon_alipay.png"),
                pay_icon_wechat: require("../../assets/pay_icon_wechat.png"),
                bill_icon_select: require("../../assets/bill_icon_select.png"),
                code_blank: require("../../assets/code_blank.png"),
                title: '填写订单信息',
                is_pause: false,
                is_error: false,
                wx_openid: '',
                ali_appid:'2021003120613488',
                ap_buyerid:'',
                ali_code:'',
                userCollectionId:'',
                userId:'',
                storeId:'',
                store_info:{
                    "headImage":"https://img01.yzcdn.cn/vant/cat.jpeg",
                    "shopName":"芝富通"
                },
                screenHeight:document.body.clientHeight,
                originHeight:document.body.clientHeight,
                isOriginHei:true,
                load_openid:false,
                load_info:false,
                load_ap_buyerid:false,
                is_submit:false
            };
        },
        mounted() {
            this.init()
            const that = this;
            this.originHeight = document.body.clientHeight
            window.onresize = ()=>{
                return(()=>{
                    window.screenHeight = document.body.clientHeight
                    that.screenHeight = window.screenHeight
                    if(window.screenHeight<that.originHeight){
                        that.isOriginHei = false
                    }else{
                        that.isOriginHei = true
                    }
                    console.log(that.originHeight,window.screenHeight)
                })()
            }
            window.addEventListener("unload", (e) => this.unloadHandler(e));
        },
        activated() {

        },
        destroyed() {
            window.removeEventListener("unload", (e) => this.unloadHandler(e));
        },
        methods: {
            // 页面关闭
            // eslint-disable-next-line no-unused-vars
            unloadHandler(e) {
                // 退出登录
                this.closeListener();
            },
            closeListener(){
                fetch('http://116.205.157.214:8127/sys/yl/bank/ylAreaList?shortNo=2102').then((res)=>{
                    console.log(res)
                })
            },
            formatter(value) {
                return value.replace(/([0-9]+\.[0-9]{2})[0-9]*/,"$1");
            },
            init(){
                const query = this.$route.query;
                if(query.hidden != undefined && parseInt(query.hidden) === 1){

                    this.is_hidden = true;
                    document.title = '微信支付'
                } else{
                    document.title = '填写订单信息'
                }

                if(query.id == undefined){
                    this.is_error = true
                    return;
                }else{
                    this.storeId = query.id
                    this.getStoreInfo()
                }
                if (this.is_alipay) {
                    Toast.loading({
                        message:'载入中...',
                        forbidClick: true,
                        duration: 0, // 持续展示 toast
                    })
                    const s = document.createElement('script');
                    s.type = 'text/javascript';
                    s.src = 'https://gw.alipayobjects.com/as/g/h5-lib/alipayjsapi/3.1.1/alipayjsapi.min.js';
                    document.body.appendChild(s);
                    const _this = this;
                    window.onload = () => {   //初始化产生一个全局变量 AlipayJSBridge
                        this.ready(function () {
                            // eslint-disable-next-line no-undef
                            ap.getAuthCode({
                                appId :  _this.ali_appid ,
                                scopes : ['auth_base'],
                            },function(res){
                                if(res.authCode == undefined){
                                    alert (res.errorDesc);
                                }else{
                                    Toast.clear()
                                    _this.ali_code = res.authCode
                                    _this.getAlipayUserId()
                                }
                            });
                        });
                    }
                }

                if (this.is_wxpay && query.code != undefined) {
                    const params = {
                        code:query.code
                    }

                    // const s = document.createElement('script');
                    // s.type = 'text/javascript';
                    // s.src = 'https://res2.wx.qq.com/open/js/jweixin-1.6.0.js';
                    // document.body.appendChild(s);
/*
                    const url = (window.location.href.split('#')[0]);
                    console.log(url)

                    this.$request.get('sys/wxmp/getJsapiSignature', {params: {url: url}}).then((res) => {
                        const result = res.data
                        wx.config({

                            appId: result.appId,

                            nonceStr: result.nonceStr,

                            signature: result.signature,

                            timestamp: result.timestamp,

                            debug: false,
                            jsApiList: [
                                'chooseWXPay',]
                        })
                    });*/
                    // const _this = this;
                    // window.onload = () => {
                    //     this.ready(function () {
                    //     })}
                    Toast.loading({
                        message: '加载中...',
                        forbidClick: true,
                        duration: 0, // 持续展示 toast
                    })
                    this.$request.get('sys/wxmp/getOpenId',{params}).then((res)=>{
                        Toast.clear()
                        if(parseInt(res.code)==200){
                            this.wx_openid = res.data.openId
                            this.load_openid = true
                            if(this.load_openid&&this.load_info){
                                this.payOrder();
                            }
                        }else{
                            Toast(res.data.message)
                        }
                    })

                }
                // this.getProductType()
            },
            getStoreInfo(){
                const params = {
                    id:this.storeId
                }
                let _this = this;
                this.$request.get('sys/user/getCodeStoreById',{params}).then((res)=>{
                    if(parseInt(res.code) === 200){
                        let store_info = res.data;
                        if(store_info.isBind == 0){
                            console.log(store_info)
                            this.$router.replace('bind?code='+store_info.code)
                        }else{
                            let types = res.data.productTypeList
                            this.store_info = res.data

                            _this.goods_type = types;
                            if(res.data.fixed === true){
                                _this.is_fixed = true;
                                for(let i = 0;i<types.length;i++){
                                    if(types[i].productTypeId == res.data.productTypeIdList){
                                        types[i].checked = true
                                    }
                                }
                                _this.goods_type = types;
                                _this.result = [res.data.productTypeIdList]
                                _this.memo = res.data.memo
                                _this.amount = res.data.money
                                _this.load_info = true
                                if (_this.is_wxpay || _this.is_alipay) {
                                    if(_this.is_wxpay){
                                        _this.radio = '2'
                                        if(_this.load_openid&&_this.load_info){
                                            _this.payOrder();
                                        }
                                    }else if(_this.is_alipay){
                                        _this.radio = '1'
                                        if(_this.load_ap_buyerid&&_this.load_info){
                                            _this.payOrder();
                                        }
                                    }
                                }else{
                                    // _this.submitOrder();
                                }
                            }
                            let default_result = [];
                            // eslint-disable-next-line no-debugger
                            for (let i = 0;i<types.length;i++){
                                console.log(i)
                                if(types[i].isDefault == 1){
                                    default_result.push(types[i].productTypeId)
                                    types[i].checked = true
                                }
                            }
                            _this.goods_type = types;
                            _this.result = default_result;
                            console.log(_this.result)

                        }
                    }else if(parseInt(res.code) === 500){
                        this.is_pause = true;
                        this.title = '提示'
                    }
                    else{
                        this.is_error = true
                    }
                })
            },
            getAlipayUserId(){
                const params = {
                    code:this.ali_code
                }
                this.$request.get('sys/ali/getUserId',{params}).then((res)=>{
                    console.log(res)
                    this.ap_buyerid = res.data.userId
                    this.load_ap_buyerid = true
                    if(this.load_ap_buyerid&&this.load_info){
                        this.payOrder();
                    }
                })
            },
            getProductType() {
                this.$request.get('sys/productType/list').then((res) => {
                    console.log(res)
                    this.goods_type = res.data.list
                })
            },
            ready(callback) {

                // 如果jsbridge已经注入则直接调用
                // eslint-disable-next-line no-undef
                if (window.AlipayJSBridge) {
                    callback && callback();
                } else {
                    // 如果没有注入则监听注入的事件
                    document.addEventListener('AlipayJSBridgeReady', callback, false);
                }
            },
            onChange(res) {
                console.log(res)
            },
            addRemarks() {
                this.remarks_tmp = this.remarks
                this.remarks_show = true
                this.autofocus = false
                setTimeout(function () {
                    this.autofocus = true
                }, 100)
            },
            confirmRemarks() {
                this.remarks = this.remarks_tmp
            },
            submitOrder() {
                if (this.amount == '') {
                    Toast('请填写付款金额')
                    return
                }
                console.log(parseFloat(this.amount))
                if(parseFloat(this.amount)<=0){

                    Toast('付款金额不能小于0或等于0')
                    return
                }
                if (this.result.length == 0) {
                    Toast('请选择商品类型')
                    return
                }
                if (this.is_wxpay || this.is_alipay) {
                    // this.popup_show = true
                    if(this.is_alipay){
                        this.radio = '1';
                    }else{
                        this.radio = '2'
                    }
                    this.payOrder();
                } else {
                    const params = {
                        "userId": this.store_info.userId,
                        "amount": this.amount,
                        "memo": this.remarks,
                        "productTypeIdList": this.result,
                        "userCollectionId": this.store_info.userCollectionId,
                        "qrCodeDataId":this.storeId
                    }
                    this.$router.push({name: 'pay',params:params})
                }
            },
            closePayType() {
                this.popup_show = false
            },
            payOrder() {
                let params = {
                    "userId": this.store_info.userId,
                    "amount": this.amount,
                    "memo": this.remarks,
                    "productTypeIdList": this.result,
                    "buyerid":this.ap_buyerid,
                    "openid":this.wx_openid,
                    "userCollectionId": this.store_info.userCollectionId,
                    "qrCodeDataId": this.storeId
                }

                let _this = this;
                if(this.is_submit == false){
                    Toast.loading({
                        message:'加载中...',
                        forbidClick: true,
                        duration: 0, // 持续展示 toast
                    })
                    this.is_submit = true;
                    if (this.radio == '1') {
                        const that = this;
                        this.$request.post('sys/order/orderZFBJS', params).then((res) => {
                            // this.is_submit = false;
                            setTimeout(function () {
                                _this.is_submit = false;
                            },2000)
                            if (parseInt(res.code) == 200) {
                                Toast.clear()
                                // eslint-disable-next-line no-undef
                                ap.tradePay({
                                    tradeNO: res.data.pay_url
                                }, function({resultCode}){
                                    // ap.alert(typeof resultCode)
                                    // that.popup_show = false

                                    if(resultCode == '9000'){
                                        that.$router.replace({name:'success',params:{state:1,amount:that.amount,store_info:that.store_info,uid:that.storeId}})
                                    }else{
                                        let msg = ''
                                        if(resultCode == '8000'){
                                            msg = '正在处理中'
                                        }else if(resultCode == '4000'){
                                            msg = '订单支付失败'
                                            that.closeApOrder(res.data.pay_url,resultCode)
                                        }else if(resultCode == '6001'){
                                            msg = '用户中途取消'
                                            that.closeApOrder(res.data.pay_url,resultCode)
                                        }else if(resultCode == '6002'){
                                            msg = '网络连接出错'
                                            that.closeApOrder(res.data.pay_url,resultCode)
                                        }else if(resultCode == '99'){
                                            msg = '用户点击忘记密码导致快捷界面退出'
                                            that.closeApOrder(res.data.pay_url,resultCode)
                                        }else {
                                            that.closeApOrder(res.data.pay_url,resultCode)
                                        }
                                        that.$router.push({name:'success',params:{state:0,amount:that.amount,store_info:that.store_info,uid:that.storeId,msg:msg,orderNo:'YYDS'+res.data.app_id}})
                                    }
                                });
                            }
                        })
                    } else if (this.radio == '2') {
                        params = {
                            ...params,
                            // isRaw:1
                        }
                        this.$request.post('sys/order/orderWXJS', params).then((res) => {
                            // this.is_submit = false;
                            if (parseInt(res.code) == 200) {
                                // eslint-disable-next-line no-debugger
                                debugger
                                Toast.clear()
                                location.href = res.data.pay_url

                                /*
                                this.$request.post('sys/order/parsePayUrl', {payUrl:res.data.pay_url}).then((res1) => {
                                    console.log(res1)
                                    if (parseInt(res1.code) == 200) {
                                        // eslint-disable-next-line no-undef
                                        wx.chooseWXPay({
                                            timestamp: res1.data.timeStamp, // 支付签名时间戳，注意微信 jssdk 中的所有使用 timestamp 字段均为小写。但最新版的支付后台生成签名使用的 timeStamp 字段名需大写其中的 S 字符
                                            nonceStr: res1.data.nonceStr, // 支付签名随机串，不长于 32 位
                                            package:  res1.data.package, // 统一支付接口返回的prepay_id参数值，提交格式如：prepay_id=\*\*\*）
                                            signType: res1.data.signType, // 微信支付V3的传入 RSA ,微信支付V2的传入格式与V2统一下单的签名格式保持一致
                                            paySign: res1.data.paySign, // 支付签名
                                            success: function (res_wxpay) {
                                                // 支付成功后的回调函数
                                                console.log(res_wxpay)
                                            }
                                        });
                                    }
                                })*/
                            }
                            setTimeout(function () {
                                _this.is_submit = false;
                            }, 2000)
                        })
                    } else if (this.radio == '3') {
                        this.is_submit = false;
                        Toast.clear()
                        console.log('芝富通')
                        this.$router.push('download')
                    } else {
                        _this.is_submit = false;
                        Toast.clear()
                        Toast('未选择支付方式')
                    }
                }
            },
            closeApOrder(tradeNo,code){
                this.$request.get('sys/order/cancelPayment?tradeNo='+tradeNo+"&zfbStatusCode="+code).then(() => {
                })
            },
            chooseGoodsType(index) {
                let result = this.result;
                let type = this.goods_type;
                let result_index = result.indexOf(type[index].productTypeId);
                if (result_index == -1) {
                    result.push(type[index].productTypeId)
                } else {
                    result.splice(result_index, 1);
                }
                type[index].checked = !type[index].checked
                this.goods_type = type;
                this.result = result
            }
        }
    }
</script>

<style scoped lang="less">

    #app {
        min-height: 100vh;
        color: #333;
        font-size: 0.9rem;
        background-color: #ffffff;
    }

    .content {
        padding: 20px;

        &-pause {
            padding-top: 100px;
        }
    }

    .money {
        font-size: 1.2rem;
        line-height: 3rem;
    }

    .order-type {
        margin-top: 10px;
    }

    .remarks {
        color: #1458a5;

        &-modify {
            color: #1458a5;
            margin-left: 10px;
        }
    }

    .btn {
        position: fixed;
        bottom: 30px;
        width: calc(100% - 40px);
    }

    .money_bg {
        display: flex;
        align-items: center;
        font-size: 2rem;
        background: #f5f5f5;
        margin: 10px 0px;
        padding: 8px 12px;
    }

    .pay-type-name {
        font-size: 16px;
    }

    .pay-type-desc {
        font-size: 10px;
        color: #ef2505;
    }

    .pay-type-item {
        padding: 20px 16px;
    }

    .goods-type {
        background: #f5f5f5;
        line-height: 40px;
        text-align: center;
        border-radius: 6px;
        position: relative;
        margin-right: 10px;
        border: solid 1px #f5f5f5;

        &-choose {
            background: #fff0ee;
            border: solid 1px #ef2505 !important;
            color: #ef2505;
        }
    }
    .remarks_panel{
        padding-bottom: 100px;
    }
</style>
